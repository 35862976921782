import React, { Component } from 'react';
import { BrowserRouter, Router, Redirect } from 'react-router-dom';

import { createUUID } from '../common/Utils';
import GlobalHeader from '../common/components/globalUI/GlobalHeader';

import Welcome from './containers/Welcome';
import LoginForm from './containers/LoginForm';

export default class Login extends Component {

	constructor (props) {

		super(props);

		const user = localStorage.user ? JSON.parse(localStorage.getItem('user')) : {};

		this.state = {
			errorMsgVisible: false,
			errorNetworkMsgVisible: false,
			isAuthenticated: false,
			isAuthenticating: false,
			user
		};

	}

	UNSAFE_componentWillMount () {

		this.checkIsAuthenticated();

	}

	setIsAuthenticated (isAuthenticated) {

		this.setState({ isAuthenticated });

	}

	checkIsAuthenticated = () => {

		fetch('/api/login/remaining', {
			credentials: 'same-origin',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				pragma: 'no-cache',
				'cache-control': 'no-cache'
			}
		})
			.then((res) => res.status === 200 && res.json())
			.then((timeRemaining) => {

				if (timeRemaining > 0) {

					this.setIsAuthenticated(true);

				} else {

					localStorage.removeItem('user');
					this.setIsAuthenticated(false);

				}

			})
			.catch((error) => console.log(error));

	};

	handleLoginBtnClick = (e) => {

		e.preventDefault();
		const isValid = this.validateLoginForm();

		if (!isValid) {

			this.setState(() => ({ errorMsgVisible: true }));

			return false;

		}

		const { username, password } = isValid;

		this.setState(
			{
				isAuthenticating: true
			},
			() => {

				let tabId = sessionStorage.getItem('tabId');

				if (!tabId) {

					tabId = createUUID();
					sessionStorage.setItem('tabId', tabId);

				}

				fetch('/Login', {
					method: 'post',
					body: JSON.stringify({
						username,
						password
					}),
					credentials: 'same-origin',
					headers: {
						ClientId: tabId,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						pragma: 'no-cache',
						'cache-control': 'no-cache'
					}
				})
					.then((res) => {

						if (res.status !== 200) {

							this.setState(() => ({
								errorMsgVisible: true,
								errorNetworkMsgVisible: false,
								isAuthenticating: false,
								isAuthenticated: false,
								isLoggingIn: false
							}));
							return false;

						}

						return res.json();

					})
					.then((res) => {

						if (!res) {

							return;

						}

						const url = window.location.href;
						const returnUrl = url.split('ReturnUrl=%2F')[1];

						if (returnUrl) {

							const { origin } = window.location;

							localStorage.setItem('user', JSON.stringify(res));
							window.location.href = `${origin}/${returnUrl
								.replace(/%2f/gi, '/')
								.replace(/%3f/gi, '?')
								.replace(/%3d/gi, '=')}`;

						} else {

							this.setState(
								() => ({
									errorMsgVisible: false,
									errorNetworkMsgVisible: false,
									isAuthenticating: false,
									isAuthenticated: true,
									isLoggingIn: false,
									user: res
								}),
								() => {

									localStorage.setItem('user', JSON.stringify(res));

								}
							);

						}

					})
					.catch((error) => {

						this.setState({
							errorNetworkMsgVisible: true,
							errorMsgVisible: false,
							isAuthenticating: false,
							isAuthenticated: false,
							isLoggingIn: false
						});

					});

			}
		);

	};

	handleSignOut = () => {

		localStorage.removeItem('user');
		localStorage.removeItem('tabId');
		this.setState({ isAuthenticated: false, user: {} });

	};

	validateLoginForm = () => {

		let username = document.getElementById('username');
		let password = document.getElementById('password');
		username = username.value;
		password = password.value;

		if (!username || !password) {

			return false;

		}

		return {
			username,
			password
		};

	};

	render () {

		const {
			isAuthenticated, isAuthenticating, errorMsgVisible, errorNetworkMsgVisible, user
		} = this.state;

		if (!isAuthenticated) {

			return (
				<>
					<LoginForm
						handleLoginBtnClick={this.handleLoginBtnClick}
						errorMsgVisible={errorMsgVisible}
						errorNetworkMsgVisible={errorNetworkMsgVisible}
						isAuthenticated={isAuthenticated}
						isAuthenticating={isAuthenticating}
					/>
				</>
			);

		}

		return (
			<BrowserRouter>
				<div>
					<GlobalHeader
						areaName="Dashboard"
						isAuthenticated={isAuthenticated}
						signOut={this.handleSignOut}
						user={user}
					/>
					<Welcome user={user} />
				</div>
			</BrowserRouter>
		);

	}

}
