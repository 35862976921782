import React from 'react';
import PropTypes from 'prop-types';

import { USER_ROLES } from '../../common/Config';

import './Welcome.scss';

const Welcome = ({ user: { roles } }) => (
	<div id="welcome" className="container">
		<h1>Recent Utilities</h1>
		<div className="container">
			<div className="row">
				{roles.includes(USER_ROLES.PA_VIEW)
				|| roles.includes(USER_ROLES.PA_EDIT)
				|| roles.includes(USER_ROLES.PA_PUBLISH) ? (
						<a href="/ProductAssets" className="calloutBtn alt1 utility col">
						Product Assets
						</a>
					) : null}
				{roles.includes(USER_ROLES.DBIC_VIEW)
				|| roles.includes(USER_ROLES.DBIC_EDIT)
				|| roles.includes(USER_ROLES.DBIC_VIEW) ? (
						<a href="/ProductImages/upload" className="calloutBtn alt1 utility col">
						Product Images
						</a>
					) : null}
				{roles.includes(USER_ROLES.MP_VIEW)
				|| roles.includes(USER_ROLES.MP_LISTABLE)
				|| roles.includes(USER_ROLES.MP_HOSPITALITY)
				|| roles.includes(USER_ROLES.MP_CONTENT) ? (
						<a href="/ManageProducts" className="calloutBtn alt1 utility col">
						Manage Products
						</a>
					) : null}
				{roles.includes(USER_ROLES.PR_VIEW)
				|| roles.includes(USER_ROLES.PR_EDIT_RT)
				|| roles.includes(USER_ROLES.PR_EDIT_RG) ? (
						<a href="/Relationships" className="calloutBtn alt1 utility col">
						Manage Relationships
						</a>
					) : null}
				{roles.includes(USER_ROLES.MSF_EDIT) ? (
					<a href="/SortFilters" className="calloutBtn alt1 utility col">
						Manage Sort Filters
					</a>
				) : null}
				{roles.includes(USER_ROLES.SC_VIEW) || roles.includes(USER_ROLES.SC_EDIT) ? (
					<a href="/SearchConfiguration" className="calloutBtn alt1 utility col">
						Search Configuration
					</a>
				) : null}
				{roles.includes(USER_ROLES.TOD_VIEW) || roles.includes(USER_ROLES.TOD_EDIT) ? (
					<a href="/TrainingAssets" className="calloutBtn alt1 utility col">
						Training On Demand
					</a>
				) : null}
				{roles.includes(USER_ROLES.FDSM_EDIT) ? (
					<a href="/FDSMapping" className="calloutBtn alt1 utility col">
						FDS Mapping
					</a>
				) : null}
			</div>
		</div>
	</div>
);

Welcome.propTypes = {
	user: PropTypes.shape({
		roles: PropTypes.arrayOf(PropTypes.string)
	}).isRequired
};

export default Welcome;
