import React from 'react';
import PropTypes from 'prop-types';
import {
	DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap';

import { USER_ROLES } from '../../Config';

const GlobalMenu = ({ user: { roles } }) => (
	<UncontrolledDropdown nav inNavbar className="mr-2">
		<DropdownToggle nav className="d-none d-md-block">
			<span className="navbar-toggler-icon" />
			<span className="d-md-none">Lamps Plus Utilities</span>
		</DropdownToggle>
		<DropdownToggle nav caret className="d-md-none">
			Lamps Plus Utilities
		</DropdownToggle>
		<DropdownMenu>
			<DropdownItem header className="d-none d-md-block">
				Lamps Plus Utilities
			</DropdownItem>
			{roles.includes(USER_ROLES.PA_VIEW)
			|| roles.includes(USER_ROLES.PA_EDIT)
			|| roles.includes(USER_ROLES.PA_PUBLISH) ? (
					<DropdownItem href="/ProductAssets/upload">Product Assets</DropdownItem>
				) : null}
			{roles.includes(USER_ROLES.DBIC_VIEW)
			|| roles.includes(USER_ROLES.DBIC_EDIT)
			|| roles.includes(USER_ROLES.DBIC_VIEW) ? (
					<DropdownItem href="/ProductImages/upload">Product Images</DropdownItem>
				) : null}
			{roles.includes(USER_ROLES.MP_VIEW)
			|| roles.includes(USER_ROLES.MP_LISTABLE)
			|| roles.includes(USER_ROLES.MP_HOSPITALITY)
			|| roles.includes(USER_ROLES.MP_CONTENT) ? (
					<DropdownItem href="/ManageProducts">Manage Products</DropdownItem>
				) : null}
			{roles.includes(USER_ROLES.PR_VIEW)
			|| roles.includes(USER_ROLES.PR_EDIT_RT)
			|| roles.includes(USER_ROLES.PR_EDIT_RG) ? (
					<DropdownItem href="/Relationships">Manage Relationships</DropdownItem>
				) : null}
			{roles.includes(USER_ROLES.MSF_EDIT) ? (
				<DropdownItem href="/SortFilters">Manage Sort Filters</DropdownItem>
			) : null}
			{roles.includes(USER_ROLES.SC_VIEW) || roles.includes(USER_ROLES.SC_EDIT) ? (
				<DropdownItem href="/SearchConfiguration">Search Configuration</DropdownItem>
			) : null}
			{roles.includes(USER_ROLES.TOD_VIEW) || roles.includes(USER_ROLES.TOD_EDIT) ? (
				<DropdownItem href="/TrainingAssets">Training On Demand</DropdownItem>
			) : null}
			{roles.includes(USER_ROLES.FDSM_EDIT) ? <DropdownItem href="/FDSMapping">FDS Mapping</DropdownItem> : null}
		</DropdownMenu>
	</UncontrolledDropdown>
);

GlobalMenu.propTypes = {
	user: PropTypes.shape({
		roles: PropTypes.arrayOf(PropTypes.string)
	}).isRequired
};

export default GlobalMenu;
