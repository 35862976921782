import React from 'react';
import PropTypes from 'prop-types';

import './LoginForm.scss';

class LoginForm extends React.Component {

	constructor (props) {

		super(props);

		this.state = { 

			username: null,
			password: null

		};

	}

	changeUserName = (e) => {

		this.setState({ username: e.target.value.trim() });

	}

	changePassword = (e) => {

		this.setState({ password: e.target.value.trim() });

	}

	getErrorMsg = () => {

		const lpxPath = `${window.location.origin}/imgs/x.png`;

		return (
			<span className="error">
				<img src={lpxPath} />
				This field is required
			</span>
		);

	}

	getOkMsg = () => {

		const lpcheckPath = `${window.location.origin}/imgs/check-mark.png`;

		return (
			<span className="ok"><img src={lpcheckPath} /></span>
		);

	}
	
	render () {
		
		const lpLogoPath = `${window.location.origin}/imgs/lp-logo-black.png`;				
		const errorMsgVisible = this.props.errorMsgVisible;
		const errorNetworkMsgVisible = this.props.errorNetworkMsgVisible;
		const isAuthenticating = this.props.isAuthenticating;

		return (
			<div id='loginForm' className='container'>
				<div className='row'>
					<div className='col-sm-12'>
						<div className="image-container">
							<img src={lpLogoPath}></img>
						</div>
						<p className='text-muted'>Super CMS</p>
					</div>
				</div>

				{errorMsgVisible ? <p className='invalidLogin'>Invalid Username and/or Password</p> : <p>&nbsp;</p>}
				{errorNetworkMsgVisible && <p className='invalidLogin'>There was a problem logging in, please try again.</p>}
				
				<form className='lpForm' role='form' autoComplete='off' id='formLogin' noValidate='' method='POST' onSubmit={(e)=>this.props.handleLoginBtnClick(e)}>
					<div className='row'>
						<div className='col-sm-6'>							
							<div className='field'>
								<label htmlFor='username'>Username</label>
								<input className='' type='text' name='username' id='username' onChange={this.changeUserName} />
								<span className="validationMessage">
									{errorMsgVisible 
										? this.state.username === null || this.state.username === '' ? this.getErrorMsg() : this.getOkMsg()
										: null
									}
								</span>						
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='field'>
								<label>Password</label>
								<input className='' type='password' name='password' id='password' onChange={this.changePassword} />
								<span className="validationMessage">
									{errorMsgVisible 
										? this.state.password === null || this.state.password === '' ? this.getErrorMsg() : this.getOkMsg()
										: null
									}
								</span>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-3'>
							<button
								className='calloutBtn'
								type='submit'
								id='loginBtn'
							>
								Login
							</button>

							{isAuthenticating && <p>Logging in...</p> }
						</div>
					</div>
				</form>
			</div>
		);

	}

};

const propTypes = {
	handleLoginBtnClick: PropTypes.func,
	errorMsgVisible: PropTypes.bool,
	errorNetworkMsgVisible: PropTypes.bool,
	isAuthenticating: PropTypes.bool
};

LoginForm.propTypes = propTypes;

export default LoginForm;