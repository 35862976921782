import React from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	NavItem,
	NavLink,
	UncontrolledDropdown
} from 'reactstrap';
import PropTypes from 'prop-types';

function GlobalLoginStatus ({ loggedIn, user, signOut }) {

	const userInfo = {
		name: user && user.firstName,
		...user
	};


	const handleSignOut = () => {

		signOut && signOut();
		window.location.href = '/logout';

	};

	if (loggedIn) {

		return (
			<UncontrolledDropdown nav inNavbar className="mr-2 bold">
				<DropdownToggle nav caret>Welcome, {userInfo.firstName}</DropdownToggle>
				<DropdownMenu right>
					<DropdownItem onClick={() => handleSignOut()}>Sign Out</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		);

	}

	return (
		<NavItem>
			<NavLink href="/" className="signin">Sign In</NavLink>
		</NavItem>
	);

}

GlobalLoginStatus.propTypes - {
	loggedIn: PropTypes.bool.isRequired,
	signOut: PropTypes.func.isRequired,
	user: PropTypes.object
};

export default GlobalLoginStatus;
