import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
	Collapse, Navbar, NavbarBrand, NavbarToggler, Nav
} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

import GlobalMenu from './GlobalMenu';
import GlobalLoginStatus from './GlobalLoginStatus';
import './GlobalHeader.scss';

const lpLogoPath = `${window.location.origin}/imgs/lp-logo-black.png`;

class GlobalHeader extends Component {

	constructor (props) {

		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
			user: this.props.isAuthenticated && this.props.user
		};

	}

	toggle () {

		this.setState({
			isOpen: !this.state.isOpen
		});

	}

	render () {

		return (
			<Navbar light expand="md" className="mb-3" id="GlobalHeader">
				<NavbarToggler onClick={this.toggle} />
				<LinkContainer to="/">
					<NavbarBrand className="ml-3 mr-auto d-md-none">
						{this.props.areaName}
					</NavbarBrand>
				</LinkContainer>
				<Collapse isOpen={this.state.isOpen} navbar>
					<Nav className="mr-auto" id="nav-left" navbar>
						<GlobalMenu user={this.props.isAuthenticated && this.props.user} />
						<li className="image-container mr-3 d-md-block">
							<a href={window.location.origin}>
								<img src={lpLogoPath} />
								<p className="text-muted">Super CMS</p>
							</a>
						</li>
						<li>
							<LinkContainer to="/">
								<NavbarBrand className="d-none d-md-block">
									{this.props.areaName}
								</NavbarBrand>
							</LinkContainer>
						</li>
						{this.props.children}
					</Nav>
					<Nav className="ml-auto" id="nav-right" navbar>
						<GlobalLoginStatus
							loggedIn={this.props.isAuthenticated}
							signOut={this.props.signOut}
							user={this.props.isAuthenticated && this.props.user}
						/>
					</Nav>
				</Collapse>
			</Navbar>
		);

	}

}

GlobalHeader.propTypes = {
	areaName: PropTypes.string,
	children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
	isAuthenticated: PropTypes.bool,
	signOut: PropTypes.func, // callback to parent for setting state before signout and redirection
	user: PropTypes.object
};

export default withRouter(GlobalHeader);
