/**
 * This is list of user roles (rights) that are used in SCMS
 */
const USER_ROLES = {
	DBIC_EDIT: 'CMS DBIC Edit',
	DBIC_REPORTS: 'CMS DBIC Reports',
	DBIC_VIEW: 'CMS DBIC View',
	/**
	 * Allows read only access for all fields on the page
	 */
	MP_VIEW: 'CMS Manage Product View Only',
	/**
	 * Allows users read access for all fields on the page,
	 * plus edit permissions for copy/content related fields and product attributes
	 */
	MP_CONTENT: 'CMS Manage Product Content',
	/**
	 * Allows users read access for all fields on the page, plus edit permissions for hospitality flags and schemes
	 */
	MP_HOSPITALITY: 'CMS Manage Product Hospitality',
	/**
	 * Allows users read access for all fields on the page, plus edit permissions for listable flags
	 */
	MP_LISTABLE: 'CMS Manage Product Listable',
	PA_EDIT: 'CMS Product Asset Edit',
	PA_PUBLISH: 'CMS Product Asset Publish',
	PA_VIEW: 'CMS Product Asset View',
	MSF_EDIT: 'CMS Manage Sort Filters',
	SC_VIEW: 'CMS Search Configuration Utility View',
	SC_EDIT: 'CMS Search Configuration Utility Edit',
	SC_INDEX_EDIT: 'CMS Search Configuration Utility Index Edit',
	TOD_VIEW: 'CMS Training On Demand View',
	TOD_EDIT: 'CMS Training On Demand Edit',
	PR_VIEW: 'CMS View Product Relationships',
	PR_EDIT_RT: 'CMS Manage Relationship Types',
	PR_EDIT_RG: 'CMS Manage Relationship Groups',
	FDSM_EDIT: 'CMS Filter Display Set Mapping Edit'
};

const CHANGES_NOT_SAVED_MSG = 'Changes you made may not be saved.';

export { USER_ROLES, CHANGES_NOT_SAVED_MSG };
