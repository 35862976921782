const getLocalDataTime = (datetime) => `${datetime.toLocaleDateString()} ${datetime.toLocaleTimeString()}`;

const getFileNameFromPath = (path) => {

	const splitPath = path.split('/');
	return splitPath[splitPath.length - 1];

};

const createUUID = () => {

	let dt = new Date().getTime();
	const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {

		const r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);

	});

	return uuid;

};

const formatPrice = (price) => `\$${price.toLocaleString('en', { minimumFractionDigits: 2 })}`;

const formatLongSku = (sku) => sku
		?.replace(/\s+/g, '')
		.split('')
		.reduce((acc, char, index) => acc + (index === 2 || index === 6 || index === 11 ? `-${char}` : char), '');

const fromSurrogatePairToCodePoint = (h, l) => (h - 0xd800) * 0x400 + l - 0xdc00 + 0x10000;
const isSurrogatePairValid = (h, l) => !Number.isNaN(h) && !Number.isNaN(l) && ((h - 0xd800) | (l - 0xdc00)) <= 0x3ff;
const encodeChar = (charcode) => `&#${charcode};`;
/** This function was ported from .net (HttpUtility.HtmlDecode) to js */
const htmlEncode = (str) => {

	const output = [];
	for (let i = 0; i < str.length; i++) {

		if (str[i] === '<') {

			output.push('&lt;');

		} else if (str[i] === '>') {

			output.push('&gt;');

		} else if (str[i] === '"') {

			output.push('&quot;');

		} else if (str[i] === "'") {

			output.push('&#39;');

		} else if (str[i] === '&') {

			output.push('&amp;');

		} else {

			const charCode = str.charCodeAt(i);
			if (charCode >= 160 && charCode < 256) {

				// encoding required by RFC?

				output.push(encodeChar(charCode));

			} else if (charCode >= 0xd800 && charCode <= 0xdfff) {

				// surrogate pair

				const nextCharCode = str.charCodeAt(i + 1);

				if (isSurrogatePairValid(charCode, nextCharCode)) {

					const codePoint = fromSurrogatePairToCodePoint(charCode, nextCharCode);
					if (codePoint >= 0x10000) {

						output.push(encodeChar(codePoint));

					} else {

						// Don't encode BMP characters (like U+FFFD) since they wouldn't have
						// been encoded if explicitly present in the string anyway.
						output.push(String.fromCharCode(codePoint));

					}
					// since the surrogate pair is valid then we would like to skip next symbol
					i++;

				} else {

					// for invalid pair we add � to output
					output.push('\uFFFD');

				}

			} else {

				output.push(str[i]);

			}

		}

	}

	return output.join('');

};

export {
	getLocalDataTime, getFileNameFromPath, createUUID, formatPrice, formatLongSku, htmlEncode
};
